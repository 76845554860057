<script setup lang="ts">
import type { HomeBanners } from "@/types";

type TournamentBannerType = HomeBanners[number];

defineProps<{ banner: TournamentBannerType }>();

const { open } = useTaoModals();
const { t } = useT();
const router = useRouter();
const { data: appInitData } = useAppInitData();
const { data, activeCard } = useScratchCard({
	immediate: !!appInitData.value?.scratchCardLottery?.isActive
});

const claimStatus = computed(() => activeCard.value?.status === "ready_for_activate");
const btnText = computed(() => (claimStatus.value ? t("Claim") : t("Scratch & win")));

const handleWelcome = () => {
	if (claimStatus.value) {
		open("LazyOModalScratchCardsPlayForWin", { cardType: activeCard.value?.slug });
		return;
	}

	router.push("/scratch-cards/");
};
</script>

<template>
	<MBanner
		class="scratch-banner"
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:bg-color="banner.background"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="handleWelcome"
	>
		<template #default>
			<AText type="base big-md" :modifiers="['uppercase', 'italic', 'medium']">
				{{ banner?.title }}
			</AText>
		</template>
		<template #description>
			<AText type="h6 h5-md" class="small-title">
				{{ banner?.smallTitle }}
			</AText>
			<div class="prizes">
				<MPrizeFund v-if="data?.prize?.coins" icon="12/coins" variant="coins" :iconSize="28" :offset="0.5">
					<AText class="color-neutral" type="h4 h3-md">
						{{ numberFormat(data.prize.coins) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund v-if="data?.prize?.entries" icon="12/secret-coins" variant="entries" :iconSize="28" :offset="0.5">
					<AText type="h4 h3-md">
						{{ t("for free", { msg: numberFormat(data.prize.entries) }) }}
					</AText>
				</MPrizeFund>
			</div>
		</template>
		<template #actions>
			<AButton variant="primary" size="md" class="app-banner__btn">
				{{ btnText }}
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.scratch-banner {
	&:deep(.app-banner__desc) {
		gap: 16px;
		margin-bottom: 16px;
	}

	@include media-breakpoint-down(sm) {
		&:deep(.app-banner__content) {
			padding-top: 180px;
		}

		&:deep(.app-banner__desc) {
			gap: 8px;
		}

		&:deep(.app-banner__btn) {
			min-width: 240px;
		}
	}

	.small-title {
		margin-top: -6px;

		@include media-breakpoint-down(sm) {
			margin-top: 0;
		}
	}

	.prizes {
		display: flex;
		gap: 0;
		flex-direction: column;
		align-items: center;

		@include media-breakpoint-up(sm) {
			gap: 5px;
			align-items: flex-start;

			&:deep(.nuxt-icon) {
				font-size: 30px;
			}
		}
	}
}
</style>
